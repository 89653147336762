import {Routes} from '@angular/router';

export const routes: Routes = [
  {
    path: "overview-examples",
    loadComponent: () => import('./components/overview-examples/overview-examples.component').then(mod => mod.OverviewExamplesComponent),
  },
  {
    path: "samples",
    loadComponent: () => import('./components/samples/samples.component').then(mod => mod.SamplesComponent),
  },
  {
    path: ":lang/blog",
    loadComponent: () => import('./components/blog-list/blog-list.component').then(mod => mod.BlogListComponent),
  },
  {
    path: ":lang/blog/:slug",
    loadComponent: () => import('./components/blog-detail/blog-detail.component').then(mod => mod.BlogDetailComponent),
  },
  {
    path: ":lang/search/:keyQuery",
    loadComponent: () => import('./components/search/search.component').then(mod => mod.SearchComponent),
  },
  {
    path: "full-size-example",
    loadComponent: () => import('./components/full-size-example/full-size-example.component').then(mod => mod.FullSizeExampleComponent),
  },
  {
    path: "error-page",
    loadComponent: () => import('./components/error-page/error-page.component').then(mod => mod.ErrorPageComponent),
  },
  {
    path: ":lang/:slug",
    loadComponent: () => import('./components/page/page.component').then(mod => mod.PageComponent),
  },
  {
    path: ":lang",
    loadComponent: () => import('./components/page/page.component').then(mod => mod.PageComponent),
  },
  {
    path: "",
    loadComponent: () => import('./components/page/page.component').then(mod => mod.PageComponent),
  }
];
