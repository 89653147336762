import {Component, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import {LanguageService} from "./services/language.service";
import {environment} from "@env/environment";
import AOS from "aos";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{

  constructor(protected  languageService: LanguageService ) {
    this.checkLocale();
  }

  ngOnInit(): void {
    if (typeof document !== "undefined") {
      AOS.init({disable: window.innerWidth < 768});
      setTimeout(() => {
        AOS.refresh();
      }, 500);
    }
  }

  private checkLocale() {
    let language = this.languageService.getLanguage();
    if (!language) {
      this.languageService.setLanguage(environment.language);
    }
  }

}
