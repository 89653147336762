import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {environment} from "@env/environment";
import {catchError, throwError} from "rxjs";

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const authReq = req.clone({
    setHeaders: {
      Authorization: environment.api.token
    }
  });
  if (req.url.includes('graphql')) {
    return next(req);
  } else {
    return next(authReq)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            // Handle HTTP errors
            if (err.status === 401) {
              // Specific handling for unauthorized errors
              console.error('Unauthorized request:', err);
              // You might trigger a re-authentication flow or redirect the user here
            } else {
              // Handle other HTTP error codes
              console.error('HTTP error:', err);
            }
          } else {
            // Handle non-HTTP errors
            console.error('An error occurred:', err);
          }
          // Re-throw the error to propagate it further
          return throwError(() => err);
        })
      );
  }
};
