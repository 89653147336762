import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {ApplicationConfig, InjectionToken, makeStateKey, TransferState} from '@angular/core';
import {ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {environment} from "@env/environment";

const uri = environment.api.url+'/graphql'; // <-- add the URL of the GraphQL server here
const serverUri = environment.server.url+'/graphql'; // <-- add the URL of the GraphQL server here
const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');
export function apolloOptionsFactory(httpLink: HttpLink,
                                     cache: InMemoryCache,
                                     transferState: TransferState): ApolloClientOptions<any> {
  const isBrowser = transferState.hasKey<any>(STATE_KEY);
  const selectedUri = isBrowser ? uri : serverUri;
  if (isBrowser) {
    const state = transferState.get<any>(STATE_KEY, null);
    cache.restore(state);
  } else {
    transferState.onSerialize(STATE_KEY, () => cache.extract());
    // cache.reset();
  }
  return {
    link: httpLink.create({ uri:selectedUri }),
    cache,
    // cache: new InMemoryCache(),
    connectToDevTools: false,
    ssrMode: !isBrowser,
    ssrForceFetchDelay: 100,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  };
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_CACHE,
    useValue: new InMemoryCache(),
  },
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
    deps: [HttpLink, APOLLO_CACHE, TransferState]
  },
];
