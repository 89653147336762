import {ApplicationConfig, isDevMode} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration, withHttpTransferCacheOptions} from '@angular/platform-browser';
import {provideHttpClient, withFetch, withInterceptors} from '@angular/common/http';
import {graphqlProvider} from './graphql.provider';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {httpInterceptor} from "@app/interceptors/http.interceptor";
import {IMAGE_LOADER, ImageLoaderConfig} from "@angular/common";
import {provideServiceWorker} from '@angular/service-worker';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(
      // withNoHttpTransferCache(),
      withHttpTransferCacheOptions({includePostRequests: true})
    ),
    provideHttpClient(withInterceptors([httpInterceptor]), withFetch()),
    graphqlProvider,
    provideAnimationsAsync(),
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        let size = '';
        let src = config.src;
        let number: number | undefined = config.width;
        if (number) {
          if (number === 1000) {
            size = 'large_';
          } else if (number === 750) {
            size = 'medium_';
          } else if (number === 500) {
            size = 'small_';
          } else if (number === 264) {
            size = 'thumbnail_';
          }
        }
        const parts = src.split('/');
        let newSrc = '/uploads/' + size + parts[parts.length - 1];
        return `https://cms-test-img.bt24.app${newSrc}`;
      },
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: true, //!isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
};
